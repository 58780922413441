<script setup lang="ts">
import { parseMeta, productUrl } from 'lib/routing'
import { getDisplayVariant } from 'lib/product'
import type { Product, ProductBadge } from 'types/models/product'
import { useMediaQuery } from '@vueuse/core'
import { sortOrder } from 'utils/array/sortOrder'
import { breakpointsConfig } from '~/utils/css/breakpoints'
import type { AddToCartSources } from '~/types/gtm'

/**
 * product-grid-large = default, show all info
 * product-grid-small = image only + quickbuy on click rather than linking you to PDP
 */
export type GridType = 'product-grid-large' | 'product-grid-small' | string

interface Props {
  product: Product
  isRelated?: boolean
  slug?: string
  content?: boolean
  collectionIndex?: number
  lazy?: boolean
  hasQuickShop?: boolean
  gridType?: GridType
  showColorIndicator?: boolean
  pageSource?: AddToCartSources
}

const props = withDefaults(defineProps<Props>(), {
  isRelated: false,
  slug: '',
  content: false,
  collectionIndex: 0,
  lazy: true,
  hasQuickShop: false,
  gridType: 'product-grid-large',
  showColorIndicator: true,
  pageSource: 'otherPage',
})
const emit = defineEmits<{
  (e: 'productClick', product: Product): void
}>()
const gtm = useGTM()
const storefrontStore = useStorefrontStore()
const route = useRoute()
const speechBubbleStore = useSpeechBubbleStore()
const quickShopStatus = useQuickShopStatus()

const isSmallScreen = useMediaQuery(`(max-width: ${breakpointsConfig.phablet - 1}px)`)

const { product } = toRefs(props)
const hero = computed(() => product.value.media.hero)
const selectedVariant = computed(() => getDisplayVariant(props.product))
const titleId = computed(() => `${props.product.sku}-${props.slug}-title`)
const { isCheckoutPage, isProductPage } = parseMeta(route)
const { currency } = storeToRefs(storefrontStore)
const selectedImage = hero.value.small || hero.value.thumbnail || hero.value?.original

const showObserver = !(isCheckoutPage || (isProductPage && !props.isRelated))
const quickShopState = ref<'uninitialized' | 'visible' | 'hidden'>('uninitialized')
const delayedQuickShopState = ref<'visible' | 'hidden'>('hidden')

const { heroBadge, smallBadges } = useBadgeClassification(props.product.badges)

if (!selectedImage)
  console.error(`Missing image for: ${props.product.sku} ${props.product.name}`)

const image = {
  url: selectedImage?.url || `${process.env.baseUrlClient}/images/socks.svg`,
  label: selectedImage?.label || '🧦',
}

const { isDiscounted, originalPrice, finalPrice } = useProductPrice(currency, selectedVariant)

const isPriceConsistent = computed(() => {
  const prices = props.product.variants
    .filter(variant => variant.stockStatus !== 'no-stock')
    .map(variant => variant.price.final)
  return [...new Set(prices)].length === 1
})

interface color {
  color: string
  id: string
}

const colorIndicatorRelatedColors = computed<color[]>(() => {
  const currentColor = props.product.color.primary
  const currentSku = props.product.sku
  const relatedColors = props.product.relatedColors

  if (!currentColor)
    return relatedColors

  return [{
    color: currentColor,
    id: currentSku,
  }, ...relatedColors]
})

const discountValue = computed(
  () => props.product.badges.find(badge => badge.name === 'discount')?.value,
)

function onProductClick(e: Event) {
  // When small grid and small screen, show quick shop instead of navigating to PDP
  if (props.hasQuickShop && props.gridType === 'product-grid-small' && isSmallScreen.value) {
    e.preventDefault()
    showQuickShop()
    return
  }

  if (props.isRelated) {
    gtm.pushProductPageEvent({
      action: 'similarProduct',
      label: props.product.name,
      sku: props.product.sku,
    })
  }

  gtm.pushProductClick({
    product: props.product,
    collectionContent: props.content,
    collectionIndex: props.collectionIndex,
    listInfo: props.isRelated ? `Related products for: ${props.product.name}` : '',
  })

  emit('productClick', props.product)
}

function intersected() {
  gtm.pushProductImpression({
    product: props.product,
    collectionContent: props.content,
    collectionIndex: props.collectionIndex,
    listInfo: props.isRelated ? `Related products for: ${props.product.name}` : '',
  })
}

function showQuickShop() {
  quickShopStatus.value = 'visible'
  quickShopState.value = 'visible'
  setTimeout(() => {
    delayedQuickShopState.value = 'visible'
  }, 400)
  if (isSmallScreen.value)
    document.body?.classList.add('lock-scroll')
}

function hideQuickShop() {
  quickShopStatus.value = 'visible'
  quickShopState.value = 'hidden'
  delayedQuickShopState.value = 'hidden'
  if (isSmallScreen.value)
    document.body?.classList.remove('lock-scroll')
}

onBeforeUnmount(() => {
  document.body?.classList.remove('lock-scroll')
})

/* Inline composable to classify badges to show in the product card */
function useBadgeClassification(productBadges: ProductBadge[]) {
  const heroBadgesList = ['multi-buy', 'interpride', 'last-chance', 'special-edition', 'version-2', 'gift-idea', 'new']
  const smallBadgesList = ['low-stock', 'bestsellers', 'organic-cotton']

  const classifyBadges = (includedBadgeNames: string[]) => {
    return productBadges.filter(badge => includedBadgeNames.includes(badge.name))
  }

  const heroBadges = computed(() => classifyBadges(heroBadgesList))
  const smallBadges = computed(() => classifyBadges(smallBadgesList))

  const heroBadge = computed(() => sortOrder(heroBadges.value, heroBadgesList)[0])
  const smallBadgesOrdered = computed(() => sortOrder(smallBadges.value, smallBadgesList))

  return {
    heroBadge,
    smallBadges: smallBadgesOrdered,
  }
}
</script>

<template>
  <div
    class="product-card__container" :class="[
      {
        'overflow--hidden': delayedQuickShopState === 'hidden',
        'overflow--visible': delayedQuickShopState === 'visible',
      },
    ]"
    data-test="product-card-container"
  >
    <div class="product-card" :class="[{ 'sold-out': product.stockStatus === 'no-stock' }]" :aria-labelledby="titleId">
      <article
        :data-sku="product.sku"
        :aria-labelledby="titleId"
        data-test="collection-product"
        @click="onProductClick"
      >
        <div class="badge-container">
          <ProductBadgeHero
            v-if="heroBadge && gridType !== 'product-grid-small'"
            :badge="heroBadge"
          />
        </div>
        <NuxtLink
          :to="{
            path: productUrl(product.sku),
            query: { ...route.query },
          }"
          class="image-wrapper"
          @click="speechBubbleStore.hideSpeechBubble()"
        >
          <ImageResponsive
            provider="fastly"
            :src="image.url"
            :alt="image.label"
            :max-width="720"
            :columns="[2, 4]"
            ratio="4:5"
            object-fit="contain"
            data-test="collection-product-image"
            :lazy="lazy"
            background-color="#FFFFFF"
          />
        </NuxtLink>

        <div :id="titleId" class="details" :class="[{ hidden: gridType === 'product-grid-small' }]">
          <div>
            <ProductColorIndicatorList v-if="showColorIndicator" :products="colorIndicatorRelatedColors" class="colors" />
            <NuxtLink
              class="title" data-test="collection-product-title"
              :to="{
                path: productUrl(product.sku),
                query: { ...route.query },
              }"
              @click="speechBubbleStore.hideSpeechBubble()"
            >
              {{ product.name }}
            </NuxtLink>

            <div class="price-wrapper" data-test="collection-product-price">
              <div v-if="isDiscounted" class="price">
                <s aria-hidden="true">
                  {{ $price(originalPrice, true) }}
                </s>
                <p class="discounted final">
                  {{ $price(finalPrice, true) }}
                </p>
              </div>
              <ProductDiscountChip :is-discounted="isDiscounted" :discount-value="discountValue" />
              <p v-if="!isDiscounted && !isPriceConsistent">
                {{ $t('fromPrice', { price: $price(finalPrice, true) }) }}
              </p>
              <p v-else-if="!isDiscounted">
                {{ $price(finalPrice, true) }}
              </p>
            </div>
          </div>
          <div class="bottom-container">
            <ProductBadgeList
              v-if="smallBadges.length > 0"
              :badges="smallBadges"
            />
            <button v-if="hasQuickShop" class="cart" @click.prevent="showQuickShop">
              <span class="cart__icon" />
            </button>
          </div>
        </div>

        <Observer
          v-if="showObserver"
          :options="{ threshold: 0.5 }"
          :run-once="true"
          @intersect="intersected"
        />
        <!-- keep sold-out indicator? -->
        <ProductSoldOutIndicator v-if="product.stockStatus === 'no-stock'" class="sold-out-tape" />
      </article>
    </div>

    <ClientOnly>
      <Teleport to="body" :disabled="!isSmallScreen">
        <ProductQuickShop
          v-if="hasQuickShop"
          :quick-shop-state="quickShopState"
          :product="product"
          :image="image"
          :lazy="lazy"
          :show-pdp-link="gridType === 'product-grid-small' && isSmallScreen"
          :page-source="pageSource"
          @close="hideQuickShop"
        />
      </Teleport>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.product-card {
  height: 100%;
  display: grid;
  position: relative;
  background: var(--white);
  &:nth-of-type(4n) {
    border-right: none;
  }
  &__container {
    position: relative;
  }
}

article {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.badge-container {
  position: relative;
  top: 10px;
  left: 7px;
  z-index: 1;

  @media (min-width: $tablet) {
    top: 15px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1.4rem 2.4rem;
  flex: 1;
  @media (min-width: $tablet) {
    padding: 0 2.4rem 2.4rem;
  }
}

.price-wrapper {
  display: flex;
  gap: 0.4rem;
  align-items: flex-start;
  padding-bottom: 1.8rem;

  @media (min-width: $phone-bigger) {
    padding-bottom: unset;
    flex-direction: row;
    gap: 1.6rem;
  }

  .price {
    display: flex;
    flex-direction: column;
  }

  .discounted {
  &.final {
    color: var(--red);
  }
}
}

.title,
.price-wrapper p,
.price-wrapper s {
  @include body1;
  text-wrap: wrap;
}

.title:hover {
  text-decoration: underline;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 4rem;

  .cart {
    background-color: var(--green);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;

    &__icon {
      mask: url('/icons/cart.svg') no-repeat center / contain;
      width: 1.8rem;
      height: 1.8rem;
      background-color: var(--black);
    }
    @media (min-width: calc($laptop + 1px)) {
      display: none;
      &__icon {
        display: none;
      }
    }
  }
}

.upsell-carousel .bottom-container {
  margin-top: -1rem;
}

.product-card {
  @media (min-width: calc($laptop + 1px)) {
    &:hover {
      .cart {
        display: inline-flex;
        &__icon {
          display: inline-block;
        }
      }
    }
  }
}

.sold-out-tape {
  top: 7rem;
  left: -2rem;
  position: absolute;
  transform: rotate(15deg);
  width: calc(100% + 6rem);
  left: clamp(-2rem, 10vw, -3rem);

  @media (min-width: $phone) {
    top: 12rem;
  }
}

.overflow {
  &--hidden {
    overflow: hidden;
  }
  &--visible {
    overflow: visible;
  }
}
.hidden {
  display: none;
}

.colors {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.image-wrapper {
  border: 2px solid transparent;
  &:focus-visible {
    border: 2px solid var(--black);
    border-radius: 2px;
    outline: none;
  }
}
</style>
